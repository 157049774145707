/* eslint-disable no-debugger */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from 'utils/api';
import { getPersonFullName, getDateYear } from 'utils/helper';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  schoolData: {},
  studentsList: [],
  studentsListData: {
    page: null,
    records: null,
    recordsMatch: null,
    search: null,
    status: null,
    total: null
  },
  teachersList: [],
  teachersListData: {
    recordsMatch: null
  }
};

const slice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.error = action.payload;
    },
    setSchoolData(state, action) {
      // state.isLoading = false;
      state.schoolData = action.payload;
    },
    // GET RECENT POST
    cleanStudentV3(state) {
      state.studentsList = [];
    },
    setStudentV3(state, action) {
      const studentsList = action.payload.data.map((student) => {
        student.status = student.statuses.find((status) => status.familyCode === 'STUDENT');
        student.paymentStatus = student.statuses.find((status) => status.familyCode === 'STUDENT-PAID');
        student.fullName = getPersonFullName(student.person);
        student.grade = `${student.academicGrade.shortName}`;
        student.statusName = student.status ? `${student.status.name}` : 'Sin estatus';
        student.startAcademicYear = getDateYear(student.academicYear.startDate);
        student.endAcademicYear = getDateYear(student.academicYear.endDate);
        student.statusCode = student.status ? `${student.status.code}` : 'NONE';
        student.paymentStatusCode = student.paymentStatus ? `${student.paymentStatus.code}` : 'NONE';
        student.paymentStatusName = student.paymentStatus ? `${student.paymentStatus.name}` : 'Sin estatus';
        student.paymentDate = student.payments[0] ? student.payments[0].paidDate : '';
        if (student.discount && student.discount.id > 0) {
          student.discountStatus = student.discount.active ? 'Activo' : 'Inactivo';
        } else {
          student.discountStatus = 'Sin descuento';
        }
        student.isDemo = student.person.user && student.person.user.isDemo ? 'Demo' : 'Normal';
        student.payment = student.payments[0] ? student.payments[0] : null;
        student.paymentReference = student.payments[0] ? student.payments[0].reference : '';
        student.paymentType = student.payments[0] ? student.payments[0].paymentType : '';
        if (student.logs)
          student.logs = student.logs.map((item) => {
            item.color = item.metadata ? item.metadata.color : '';
            item.message = item.metadata && item.metadata.info ? `${item.message} ${item.metadata.info}` : item.message;
            item.message =
              item.metadata && item.metadata.extraMessage
                ? `${item.message}: ${item.metadata.extraMessage}`
                : item.message;
            item.author = item.metadata ? item.metadata.author : 'Desconocido';
            return item;
          });
        student.email = student.person && student.person.email ? student.person.email : '';
        student.username =
          student.person && student.person.user && student.person.user.username ? student.person.user.username : '';
        student.password =
          student.person && student.person.user && student.person.user.username ? student.person.user.clearPwd : '';
        return JSON.parse(JSON.stringify(student));
      });
      state.studentsList = state.studentsList.concat(studentsList);
      state.studentsListData = {
        page: action.payload.page,
        records: action.payload.records,
        recordsMatch: action.payload.recordsMatch,
        search: action.payload.search,
        status: action.payload.status,
        total: action.payload.total
      };
      state.isLoading = false;
    },
    // setRecordsMatchStudentsV
    setTeachers(state, action) {
      state.teachersList = action.payload.map((teacher) => {
        teacher.personId = teacher.person.id;
        teacher.fullName = getPersonFullName(teacher.person);
        teacher.email = teacher.person ? teacher.person.email || '-' : '-';
        teacher.username = teacher.person && teacher.person.user ? teacher.person.user.username || '-' : '-';
        teacher.level =
          teacher.academicLevels && teacher.academicLevels[0] ? teacher.academicLevels[0].academicLevel.name || '' : '';
        return teacher;
      });
      state.teachersListData = {
        recordsMatch: action.payload.length
      };
      state.isLoading = false;
    },
    setStudentData(state, action) {
      state.isLoading = false;
      state.dataStudent = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function cleanStudentsV3() {
  return (dispatch) => {
    dispatch(slice.actions.cleanStudentV3());
  };
}
export function fetchSchoolData(schoolId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`schools/${schoolId}`);
      dispatch(slice.actions.setSchoolData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function fetchStudentsV3(
  schoolId,
  filterStatus,
  filterStatusPayment,
  filterGrade,
  filterLevel,
  rowsPerPage,
  academicYearId,
  page,
  isFiltered
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    if (!isFiltered) dispatch(slice.actions.cleanStudentV3());
    try {
      const response = await axiosInstance.get(
        `schools/${schoolId}/studentsV3/${academicYearId}?academicLevelId=${
          filterLevel === 'Todos' ? '' : filterLevel
        }&academicGradeId=${filterGrade === 'Todos' ? '' : filterGrade}&activeStatus=${
          filterStatus === 'Todos' ? '' : filterStatus
        }&paymentStatus=${
          filterStatusPayment === 'Todos' ? '' : filterStatusPayment
        }&records=${rowsPerPage}&page=${page}&orderBy=fullName&order=asc`
      );
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setStudentV3(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchSchoolTeachers(schoolId, academicYearId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(
        `/v2/school-personnel/school/${schoolId}?academicYearId=${academicYearId}`
      );
      dispatch(slice.actions.setTeachers(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
